.App {
  text-align: center;
}

:focus-visible {
  outline: -webkit-focus-ring-color auto 0px ;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #dcdcdc #ffffff00;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff00;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #dcdcdc;
    border-radius: 5px;
    border: 3px none #ededed;
  }

  @keyframes menu{
    0%   {opacity: 0}
    80%   {opacity: 0}
    
    100%   {opacity: 1}
  }
  @keyframes mouseBtn {
    0%   {top: -10px}
    90%   {top: 10px}
    100%   {top: -10px}
  }
  @keyframes example4 {
    0%   {opacity: 0}
    80%   {opacity: 0}
    
    100%   {opacity: 1}
  }
  @keyframes logo {
    0%   {
     
      opacity: 1
    }
    60%   {
     
      opacity: 1
    }
    98%   {
     
      opacity: 0
    }
    
    100%   {
     
      opacity: 0;
    }
  }

  @keyframes body{
    0%   {
      opacity: 0;
      
    }
    95%{
      
      opacity: 0;
    }
    100%   {
       opacity: 1;
       }
  }
  
  
  @keyframes example2{
    0%   {
      opacity: 0;
      left: -1000%
    }
    75%   {opacity: 0}
    100%   {
      left: 0%;
       opacity: 1}
  }

  @keyframes example3{
    0%   {
      opacity: 0;
      left: 42%;
      filter: grayscale(100);
    }
    80%   {
      left: 42%;
      filter: grayscale(20);
    }
    100%   {
      opacity: 1;
      filter: grayscale(0);
      left: 0%;}
  }


  @keyframes pulseGreen {
    0% {
      
      box-shadow: 0 0 0 0 #65bf76;
    }
  
    70% {
      
      box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }
  
    100% {
      
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }

  @keyframes pulseRed {
    0% {
      
      box-shadow: 0 0 0 0 #e34949;
    }
  
    70% {
      
      box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }
  
    100% {
      
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }


  .container {
    position: relative;
    width: 50px;
    height: 50px;
    /* border-right: 1px solid #4b4b4b; */
    /* height: 60px; */
    /* margin-right: 10px; */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
  }
  
  .circle {
    position: absolute;
    border-radius: 50%;
  }
  
  .center {
       width: 16px;
    height: 16px;
    background-color: #ffffff;
    /* border: 1px solid rgb(203, 203, 203); */
    /* left: 17.1px; */
    /* transform: translate(-19.5%, -19.5%);*/
  }
  
  .small {
    width: 8px;
    height: 8px;
    background-color: #ffffff;
    top: 21px;
    left: 16pt;
    /* border: 1px solid rgb(137, 137, 137); */
    /* transform: translate(-5%, -5%);*/
  }
  
  .circle1 {
    animation: rotate1 0.7s linear infinite;
    background-color: #ff6057;
    /*border: 1px solid #ab3e38*/
  }
  
  .circle2 {
    animation: rotate2 0.7s linear infinite;
    background-color: #febc2e;
    /*border: 1px solid #a57e29;*/
  }
  
  .circle3 {
    animation: rotate3 0.7s linear infinite;
    background-color: #27c840;
    /*border: 1px solid #43814d;*/
  }
  
  @keyframes rotate1 {
    0% { transform: rotate(0deg) translate(16px) rotate(0deg); }
    100% { transform: rotate(360deg) translate(16px) rotate(-360deg); }
  }
  
  @keyframes rotate2 {
    0% { transform: rotate(120deg) translate(16px) rotate(-120deg); }
    100% { transform: rotate(480deg) translate(16px) rotate(-480deg); }
  }
  
  @keyframes rotate3 {
    0% { transform: rotate(240deg) translate(16px) rotate(-240deg); }
    100% { transform: rotate(600deg) translate(16px) rotate(-600deg); }
  }